import moment from "moment";

export default class CannedMessageModel {
	id: number;
	message: string;
	created: string;

	constructor(args: any) {
		this.id = args.id || null;
		this.message = args.message || null;
		this.created = args.created || null;
	}

	async create() {
		const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/cannedMessages";

		const data = {
			cannedMessage: {
				...this,
				created: moment().format(),
			},
		};

		const response = await fetch(endpoint, {
			method: "POST",
			cache: "no-store",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const json = await response.json();

		if (!!json.error) throw new Error(json.error);

		return new CannedMessageModel(json.cannedMessage);
	}

	async delete() {
		const endpoint = `${process.env.VUE_APP_SHEETY_ENDPOINT}/cannedMessages/${this.id}`;

		const response = await fetch(endpoint, {
			method: "DELETE",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		if (!response.ok) throw new Error("Failed to delete message");

		return true;
	}

	static async fetchBy(key: null | string = null, value: null | string = null) {
		let queries = [] as string[];
		let endpoint = `${process.env.VUE_APP_SHEETY_ENDPOINT}/cannedMessages`;

		if (!!key && !!value) {
			queries.push(`?filter[${key}]=${value}`);
		}

		if (queries.length > 0) {
			endpoint += `?${queries.join("&")}`;
		}

		const response = await fetch(endpoint, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const json = await response.json();

		if (!!json.error) throw new Error(json.error);

		return json.cannedMessages.map((message: any) => new CannedMessageModel(message));
	}
}
