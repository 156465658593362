













































































































































import Vue from "vue";
import confetti from "canvas-confetti";
import Toasted from "vue-toasted";
import filters from "@/global/filters";
import sms from "@/global/sms";
import OptionModel from "@/models/OptionModel";
import GuestModel from "@/models/GuestModel";
import ChargeModel from "@/models/ChargeModel";
import PaymentFormComponent from "@/components/PaymentFormComponent.vue";
import variables from "@/global/variables";

Vue.use(Toasted, {
	position: "bottom-left",
	fullWidth: true,
	duration: 8000,
});

interface SuccessResponse {
	success: boolean;
	stripe_charge_id: string;
	stripe_receipt_url: string;
}

interface ErrorResponse {
	success: boolean;
	error_message: string;
}

export default Vue.extend({
	data() {
		return {
			isLoading: true as boolean,
			isReady: false as boolean,
			guests: null as null | false | GuestModel[],
			loading: true as boolean,
			refreshing: false as boolean,
			bidNumber: null as null | number,
			charges: [] as ChargeModel[],
			refreshDelay: null as null | number,
			refreshTimer: null as null | number,
			hasConfettied: false as boolean,
			bidNumberInput: null as null | string,
			isAdmin: false as boolean,
			showPaidCharges: false as boolean,
			manualPayMethod: "cash" as string,
			isProcessing: false as boolean,
			isRefreshing: false as boolean,
			checkoutMessage: "" as string,
		};
	},

	async created() {
		const isReady = await OptionModel.getByKey("is_checkout_ready");

		this.isReady = !!isReady && isReady.value === "true" ? true : false;
		this.bidNumber = this.$route.params.bidNumber ? parseInt(this.$route.params.bidNumber) : null;
		this.isAdmin = this.$route.query.admin === "1" ? true : false;

		if (this.isReady) {
			const refreshDelay = await OptionModel.getByKey("checkout_page_refresh_in_minutes");
			const afterPartyCutoff = await OptionModel.getByKey("after_party_cutoff");

			if (!!afterPartyCutoff) {
				const currentTime = new Date();
				const isAfterParty = currentTime < new Date(afterPartyCutoff.value);
				const appliedCheckoutMessage = isAfterParty ? await OptionModel.getByKey("after_party_checkout_message") : await OptionModel.getByKey("checkout_message");

				if (!!appliedCheckoutMessage) {
					this.checkoutMessage = appliedCheckoutMessage.value;
				}
			}

			if (!!this.bidNumber) {
				this.guests = await GuestModel.fetchByBidNumber(this.bidNumber);
			}

			await this.loadInvoice();

			this.refreshDelay = !!refreshDelay ? parseInt(refreshDelay.value) * 60000 : 60000;
			this.refreshTimer = setInterval(() => this.loadInvoice(true), this.refreshDelay);
		}

		this.isLoading = false;

		if (this.isReady && !this.isAdmin && !!this.charges.length && !!this.hasPaid) {
			this.celebrate();
		}
	},

	beforeDestroy() {
		if (!!this.refreshTimer) clearInterval(this.refreshTimer);
	},

	methods: {
		async loadInvoice(isRefreshing: boolean = false) {
			this.isRefreshing = isRefreshing;

			if (!this.isProcessing && !!this.guests && !!this.guests.length) {
				this.charges = await ChargeModel.fetchByBidNumber(this.guest!.bidNumber);
			}

			this.isRefreshing = false;
		},

		paymentError(errorResponse: ErrorResponse) {
			this.$toasted.error(errorResponse.error_message + " Please see the checkout table for assistance.");
		},

		async processPayment(successResponse: SuccessResponse) {
			if (successResponse.success && !!this.unpaidCharges) {
				for (const item of this.unpaidCharges) {
					item.markPaid(successResponse.stripe_charge_id).update();
				}
			}

			this.celebrate();
			this.sendReceipt(successResponse);
		},

		async markPayment() {
			this.isProcessing = true;

			for (const item of this.unpaidCharges) {
				let charge = new ChargeModel(item);

				charge.paymentId = this.manualPayMethod;

				await charge.update();

				item.paymentId = charge.paymentId;
			}

			this.celebrate();
			this.sendReceipt();

			this.isProcessing = false;
		},

		// Send a receipt to the guest
		async sendReceipt(successResponse: null | SuccessResponse = null) {
			if (!!this.guest && !!this.guest.phone) {
				const fromPhone = await OptionModel.getByKey("sms_from_phone_number");
				const message = await OptionModel.getByKey("sms_payment_receipt");

				if (!!fromPhone && !!message) {
					const mergedMessage = sms.merge(
						{
							receiptUrl: !!successResponse ? successResponse.stripe_receipt_url : `${variables.appUrl}/checkout/${this.bidNumber}`,
						},
						message.value
					);

					await sms.send(this.guest.phone, fromPhone.value, mergedMessage);
				}
			}
		},

		celebrate() {
			var count = 300;
			var defaults = {
				origin: { y: 0.25 },
			};

			const fire = (particleRatio: number, opts: any) => {
				confetti({
					...defaults,
					...opts,
					particleCount: Math.floor(count * particleRatio),
				});
			};

			fire(0.25, {
				spread: 26,
				startVelocity: 55,
			});
			fire(0.2, {
				spread: 60,
			});
			fire(0.35, {
				spread: 100,
				decay: 0.91,
				scalar: 0.8,
			});
			fire(0.1, {
				spread: 120,
				startVelocity: 25,
				decay: 0.92,
				scalar: 1.2,
			});
			fire(0.1, {
				spread: 120,
				startVelocity: 45,
			});

			this.hasConfettied = true;
		},

		currencyFormat(value: any) {
			return filters.formatCurrencyStr(value);
		},
	},

	computed: {
		guest(): null | GuestModel {
			return this.guests ? this.guests[0] : null;
		},

		unpaidItemTotal(): number {
			let total = 0;

			if (this.unpaidCharges) {
				this.unpaidCharges.forEach((item: ChargeModel) => {
					if (item.amount) {
						total += item.amount;
					}
				});
			}

			return parseFloat(total.toFixed(2));
		},

		hasPaid(): boolean {
			return !!!this.unpaidCharges.length;
		},

		hasItemsButPaid(): boolean {
			return !!this.charges.length && this.hasPaid;
		},

		guestNames(): string | null {
			if (this.guests) {
				let names = [] as Array<string>;

				this.guests.forEach((guest: GuestModel) => {
					names.push(guest.fname.charAt(0) + ". " + guest.lname);
				});

				return names.join(", ");
			}

			return null;
		},

		unpaidCharges(): ChargeModel[] {
			if (this.charges) {
				let unpaidCharges = this.charges.filter((item: ChargeModel) => !!!item.paymentId);
				return unpaidCharges !== undefined ? unpaidCharges : [];
			}

			return [];
		},

		paidCharges(): ChargeModel[] {
			if (this.charges) {
				let paidCharges = this.charges.filter((item: ChargeModel) => !!item.paymentId);
				return paidCharges !== undefined ? paidCharges : [];
			}

			return [];
		},

		showNavigation(): boolean {
			return this.bidNumber && this.isAdmin && !this.loading ? true : false;
		},
	},

	watch: {
		unpaidCharges(value) {
			if (this.charges.length && this.unpaidCharges.length === 0 && !this.hasConfettied) {
			}
		},

		$route(to, from) {},
	},

	components: {
		PaymentFormComponent,
	},
});
