











import Vue from "vue";
import filters from "@/global/filters";
import ChargeModel from "@/models/ChargeModel";
import Odometer from "odometer";

import "odometer/themes/odometer-theme-minimal.css";

export default Vue.extend({
	data() {
		return {
			isLoading: true as boolean,
			bidQueue: [] as ChargeModel[],
			odometerEl: null as null | HTMLElement,
			odometer: null as any | null,
		};
	},

	async mounted() {
		await this.loadBids();

		this.odometerEl = this.$refs.odometer as HTMLElement;

		this.odometer = new Odometer({
			el: this.odometerEl,
			theme: "minimal",
			format: "(,ddd)",
		});

		this.updateBidTotal();

		window.addEventListener("storage", (e) => {
			if (e.key === "bids" && !!e.newValue) {
				let data = JSON.parse(e.newValue) as ChargeModel[];
				this.bidQueue = data;
				this.updateBidTotal();
			}
		});

		this.isLoading = false;
	},

	methods: {
		formatCurrency(value: number) {
			return filters.currency(value);
		},

		async loadBids() {
			let bids = window.localStorage.getItem("bids");

			if (!!bids) {
				for (let bid of JSON.parse(bids) as ChargeModel[]) {
					this.bidQueue.push(new ChargeModel(bid));
				}
			}
		},

		getTotalBidValue() {
			let total = 0;

			for (const bid of this.bidQueue) {
				total += bid.amount as number;
			}

			return total;
		},

		updateBidTotal() {
			this.odometerEl!.innerHTML = this.getTotalBidValue().toString();
		},
	},

	computed: {},
});
