

























































import Vue from "vue";
import ChargeModel from "@/models/ChargeModel";
import filters from "@/global/filters";
import GuestModel from "@/models/GuestModel";
import AuctionItemModel from "@/models/AuctionItemModel";

interface Report {
	label: string;
	name: string;
}

export default Vue.extend({
	data() {
		return {
			isLoading: true as boolean,
			selectedReport: null as null | Report,
			guests: [] as GuestModel[],
			charges: [] as ChargeModel[],
			auctionItems: [] as AuctionItemModel[],
			reports: [
				{
					label: "Top Bidders",
					name: "top-bidders",
				},
				{
					label: "Top Auction Items",
					name: "auction-items",
				},
				{
					label: "Outstanding Balances",
					name: "outstanding-balances",
				},
			] as Report[],
		};
	},

	async created() {
		if (!!!this.selectedReport) {
			this.selectedReport = this.reports[0];
		}

		this.charges = await ChargeModel.fetchBy();
		this.guests = await GuestModel.fetchAll();
		this.auctionItems = await AuctionItemModel.fetchBy();

		this.isLoading = false;
	},

	methods: {
		guestNames(guests: GuestModel[]) {
			if (guests.length === 1) return `${guests[0].fname} ${guests[0].lname}`;
			else if (guests.length === 2) return `${guests[0].fname} & ${guests[1].fname} ${guests[1].lname}`;
			else return `${guests[0].fname} ${guests[0].lname} & ${guests.length - 1} others`;
		},

		formatCurrency(value: number) {
			return filters.currency(value);
		},
	},

	computed: {
		chargesByBidder(): { [key: number]: number } {
			let chargesByBidNumber = {} as { [key: number]: number };

			this.charges.forEach((charge) => {
				if (!chargesByBidNumber[charge.bidNumber]) {
					chargesByBidNumber[charge.bidNumber] = 0;
				}
				chargesByBidNumber[charge.bidNumber] += charge.amount;
			});

			return chargesByBidNumber;
		},

		unpaidChargesByBidder(): { [key: number]: number } {
			let unpaidBidders = {} as { [key: number]: number };
			let unpaidCharges = this.charges.filter((charge) => charge.amount > 0 && charge.paymentId === null);

			for (let i = 0; i < unpaidCharges.length; i++) {
				if (!unpaidBidders[unpaidCharges[i].bidNumber]) {
					unpaidBidders[unpaidCharges[i].bidNumber] = 0;
				}
				unpaidBidders[unpaidCharges[i].bidNumber] += unpaidCharges[i].amount;
			}

			return unpaidBidders;
		},

		topBidders(): { guests: GuestModel[]; totalCharges: number }[] {
			if (!this.selectedReport || this.selectedReport.name !== "top-bidders") return [];

			let topBidders = Object.keys(this.chargesByBidder).map((bidNumber: string) => {
				return {
					guests: this.guests.filter((guest) => !!guest.bidNumber && guest.bidNumber == parseInt(bidNumber)),
					totalCharges: this.chargesByBidder[parseInt(bidNumber)],
				};
			});

			return topBidders.sort((a, b) => b.totalCharges - a.totalCharges) as { guests: GuestModel[]; totalCharges: number }[];
		},

		outstandingBalances(): { guests: GuestModel[]; totalCharges: number }[] {
			if (!this.selectedReport || this.selectedReport.name !== "outstanding-balances") return [];

			let outstandingBalances = Object.keys(this.unpaidChargesByBidder).map((bidNumber: string) => {
				return {
					guests: this.guests.filter((guest) => !!guest.bidNumber && guest.bidNumber == parseInt(bidNumber)),
					totalCharges: this.unpaidChargesByBidder[parseInt(bidNumber)],
				};
			});

			return outstandingBalances.sort((a, b) => b.totalCharges - a.totalCharges) as { guests: GuestModel[]; totalCharges: number }[];
		},

		sortedAuctionItems(): { charge: ChargeModel; auctionItem: AuctionItemModel }[] {
			return this.charges
				.filter((item: ChargeModel) => item.type === "Auction Item")
				.sort((a: ChargeModel, b: ChargeModel) => b.amount - a.amount)
				.map((item: ChargeModel) => {
					let itemNumber = item.sku.replace("auction-item-", "");

					console.log(itemNumber);

					return {
						charge: { ...item } as ChargeModel,
						auctionItem: this.auctionItems.find((item: AuctionItemModel) => item.itemNumber == parseInt(itemNumber)) as AuctionItemModel,
					};
				});
		},
	},

	watch: {},
});
